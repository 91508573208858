import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Atlanta Video Production - Content Marketing -  Websuasion"
        ogDescription="Boost your brand with Atlanta Video Production: Engaging, optimized YouTube content that elevates visibility and credibility, maximizing ROI."
        image="/images/video/atlanta-video-production.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
					<h1>Atlanta Video Production</h1>
					<p>
						Did you show up here because your marketing folks said they needed video? They know video content gets the best return on investment. 83% of marketers would like to create more video content if time, resources, and budget weren't obstacles (<a href="https://buffer.com/resources/social-media-2016">Buffer, 2016</a>).
					</p>
					<p>
						But you run a successful business. You can't take considerable time and resources away from it to produce video content. But you also can't ignore such a critical method to increase your sales.
          </p>
					</div>
        }
        textRight={
          <div>
            <img src="/images/video/atlanta-video-production.webp" className="rounded-image" alt="Atlanta Video Production" />
          </div>
        }
      />
      <PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
			      <div>
							<h2>Our Solution</h2>
							<p>
								We've built our facility and framework to produce quality, ongoing video series content. We create content that builds credibility. Content that keeps you top-of-mind. Content that differentiates you from your competition. Content that puts you into more sales conversations.
			        </p>
			        <p>
			          <img src="/images/video/phil-town-video.jpg" className="rounded-image" alt="Atlanta Video Production" />
			        </p>
			      </div>
	        }
	        textRight={
						<div>
		          <img src="/images/video/websuasion-studio-facility.jpg" className="rounded-image" alt="Atlanta Video Production Facility" width="401" />
		        </div>
	        }
	      />
			</PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/video/youtube-series-video-production.jpg" className="rounded-image" alt="YouTube Series Video Production - Websuasion" />
          </div>
        }
        textRight={
		      <div>
					<h2>YouTube Series Video Production</h2>
					<p>
						YouTube is the second largest search engine after Google. And because Google owns YouTube, they place high search value on the videos you post there. YouTube videos appear in search engine results alongside webpage results. We create informative videos based on the topics most searched by your ideal clients. Then, we format the video metadata so your videos show up for those search terms.
					</p>
					<p>
						YouTube is also the second biggest social network. Yet, only 62% of businesses use YouTube as a channel to post video content (<a href="https://buffer.com/state-of-social-2019" target="_blank" rel="noopener">Buffer, 2019</a>). That other 38 % of businesses are missing out because 90% of people say they discover new brands or products on YouTube (<a href="https://www.thinkwithgoogle.com/feature/youtube-strategy-to-drive-action/">Thinkwithgoogle, 2019</a>). You can get the edge over many of your competitors by posting quality content to YouTube.
		      </p>
					  <FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
		      </div>
        }
      />
	    <PageWrapper color="black">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div className="double-frame-block">
						<h2>Series Video Production Leads To Recognition</h2>
						<p>
							You want recognition for the experience and knowledge you've cultivated throughout your career. YouTube Series video production provides the platform to build credible content. You can speak in detail about your topics. You can provide comprehensive case studies and scenarios. Prospects and colleagues who subscribe to your channel will see you are a leader in your field.
	          </p>
						</div>
	        }
	      />
	    </PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
					textPadded
	        textLeft={
	          <div>
	            <img src="/images/video/youtube-subscriber-relationship.jpg" className="rounded-image" alt="Virtual Relationships Through Video" />
	          </div>
	        }
	        textRight={
	          <div>
							<h2>Building A Virtual Relationship</h2>
							<p>
								Series video production is not a general advertisement for your business. It isn't aimed at a broad, general market. We hone your series content to speak to your highest value clients. The content appeals to investigative prospects who vet their choices. Through your video series, you build a weekly relationship with key prospects. When they are ready to decide on a product or service, you will be the first place they turn.
	          	</p>
						</div>
	        }
	      />
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/video/consistent-frequent-video-posting.jpg" className="rounded-image" alt="Consistent Frequent Video Posting" />
	          </div>
	        }
	        textRight={
	          <div>
							<h2>Consistent And Frequent Video Distribution</h2>
							<p>
								A common business mistake is to produce one long, comprehensive video. It's better to create short, digestible videos on specific topics. 1-minute to 10-minute lengths tend to perform best. You can focus each video on a distinct topic that will appeal to your prospects. And you can spread those topics out over the coming weeks and months to maximize your exposure.
	          	</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/digital-course-creation/">Digital Course Creation</a></h3>
            <p>
              Transform expertise into new income streams with our digital course creation service. Maximize reach and efficiency, from concept to global client engagement.
            </p>
          </div>
        }
        textCenter={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/podcast-production/">Podcast Production</a></h3>
            <p>
              Websuasion crafts engaging podcasts for leaders, turning deep conversations into content that builds authority and connections.
            </p>
          </div>
        }
        textRight={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/webinar-production/">Evergreen Webinars</a></h3>
            <p>
              Discover the power of evergreen webinars to educate, engage, and convert prospects to customers. Enable sales at scale without geographical and time limitations.
            </p>
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
